/*eslint-disable*/
import React from 'react';
// used for making the prop types of this component
import PropTypes from 'prop-types';

// reactstrap components
import { Container, Row, Nav, NavItem, NavLink } from 'reactstrap';

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <Container fluid>
          <Nav>
            <NavItem>
              <NavLink href="https://discord.gg/sb4mkdH" target="_blank">
                Discord Link
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="https://herc.ws/board/topic/11595-ddelaysanti-nodelays-releasing-this-weekend"
                target="_blank"
              >
                Forum Link
              </NavLink>
            </NavItem>
          </Nav>
          <div className="copyright">
            © {new Date().getFullYear()} made with <i className="tim-icons icon-heart-2" /> by{' '}
            <a href="#" rel="noopener noreferrer" target="_blank">
              Creative Tim
            </a>
            , Modified by{' '}
            <a href="#" rel="noopener noreferrer" target="_blank">
              Dastgir
            </a>
          </div>
        </Container>
      </footer>
    );
  }
}

export default Footer;
