import React from 'react';

class RefreshPage extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (this.formRef) {
      this.formRef.current.submit();
    }
  }

  render() {
    const params = [
      'MID',
      'CHANNEL_ID',
      'INDUSTRY_TYPE_ID',
      'ORDER_ID',
      'TXN_AMOUNT',
      'CUST_ID',
      'CALLBACK_URL',
      'EMAIL',
      'MOBILE_NO',
      'CHECKSUMHASH',
      'WEBSITE',
    ];
    return (
      <>
        <h1>Processing Request. Please do not refresh</h1>
        <form ref={this.formRef} action={this.props.data.URL} method="POST">
          {params.map((value, index) => {
            return <input type="hidden" name={value} value={this.props.data[value]}></input>;
          })}
        </form>
      </>
    );
  }
}

export default RefreshPage;
