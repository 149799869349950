import React from 'react';

// reactstrap components
import axios from 'axios';

import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import Services from '../../../../components/dashboard/Services';

class ModifySubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingLog: true,
      pendingLog: {},
      colSize: 12,
    };
  }
  componentDidMount() {
    axios
      .get('/api/admin/subscription/pending')
      .then(res => {
        this.setState({
          loadingLog: false,
          pendingLog: res.data.log,
        });
      })
      .catch(err => {
        console.log(err.response);
      });
  }

  render() {
    if (this.state.loading) {
      return <div className="content">Loading...</div>;
    }
    return (
      <Services
        pendingPayment={this.state.loadingLog === false ? this.state.pendingLog : null}
        colSize={this.state.colSize}
        isAdmin={true}
      />
    );

    //return <div className="content">Loading Done</div>;
  }
}

ModifySubscription.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(
  mapStateToProps,
  null,
)(ModifySubscription);
