import React from 'react';
//import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import axios from 'axios';
//import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING } from './types';
import { Button } from 'reactstrap';
import { Row, Col, Card, CardBody, CardTitle, CardText, Form, FormGroup, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';

class PayPal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      plan_id: props.plan_id,
      amount: props.amount,
      input: '',
      txnSend: false,
    };
    this.send = this.send.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    this.setState({
      input: e.target.value,
    });
  }
  send() {
    const userData = {
      id: this.props.auth.user.id,
      transactionID: this.state.input,
      plan: this.state.plan_id,
    };
    axios
      .post('/api/payments/paypal/complete', userData)
      .then(res => {
        if (res.data.status && res.data.status === 'success') {
          this.setState({
            txnSend: true,
          });
        } else {
          if (res.data.error && res.data.error.status) {
            toast.error('Error: ' + res.data.error.status, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      })
      .catch(err => {
        let data = err.response.data;
        if (data && data.status) {
          toast.error('Error: ' + data.status, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast.error('Unknown Error, Please Try again later', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
  }
  render() {
    if (!this.state.txnSend) {
      var paypal_link = `https://paypal.me/dastgirp/${this.state.amount}USD`;
      return (
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <CardTitle>Payment Instruction</CardTitle>
                  <CardText>
                    Please send money at{' '}
                    <a href={paypal_link} target="_blank" rel="noopener noreferrer">
                      Click Here
                    </a>{' '}
                    and enter the transaction ID below
                  </CardText>
                  <Form>
                    <FormGroup>
                      <Input
                        type="text"
                        name="transactionID"
                        value={this.state.input}
                        onChange={this.onChange}
                      />
                    </FormGroup>
                  </Form>

                  <Button onClick={this.send}>Submit Request</Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      );
    } else {
      return (
        <div className="content">
          <Col md="12">
            <Card>
              <CardBody>
                <CardTitle>Order Status</CardTitle>

                <CardText>
                  Order has been submitted. Please wait while we manually verify your claim. You can
                  view the status at Your Subscriptions Page
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </div>
      );
    }
    /*
    if (this.state.plan_id <= 0) {
      this.props.history.push('/dashboard');
      return <></>;
    } else if (this.state.plan_id > 0) {
      return <div className="content">Please Enter your information</div>;
    } else {
      return (
        <div className="content">
          <Button onClick={this.send}>Test Payload</Button>
        </div>
      );
    }
    */
  }
}

PayPal.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(PayPal);
