import React from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from 'reactstrap';

import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

class UserProfile extends React.Component {
  render() {
    const { user } = this.props.auth;
    return (
      <>
        <div className="content">
          <Row>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">Profile</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Name</label>
                          <Input
                            disabled
                            defaultValue={user.name}
                            placeholder="Name"
                            name="name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label htmlFor="exampleInputEmail1">Email address</label>
                          <Input disabled defaultValue={user.email} name="email" type="email" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Mobile Number</label>
                          <Input
                            disabled
                            placeholder="Mobile Number"
                            value={user.mobile_no}
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
                <CardFooter></CardFooter>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-user">
                <CardBody>
                  <CardText />
                  <div className="author">
                    <div className="block block-one" />
                    <div className="block block-two" />
                    <div className="block block-three" />
                    <div className="block block-four" />
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      {/*<img alt="..." className="avatar" src={require('assets/img/emilyz.jpg')} />*/}
                      <h5 className="title">{user.name}</h5>
                    </a>
                    <p className="description">Statistics</p>
                  </div>
                  <div className="card-description">
                    <Row>
                      <Col xs={{ offset: 4 }} md={{ offset: 4 }}>
                        Your Subscriptions:{' '}
                      </Col>
                      <Col>WIP</Col>
                    </Row>
                    <Row>
                      <Col xs={{ offset: 4 }} md={{ offset: 4 }}>
                        Online Players:{' '}
                      </Col>
                      <Col>WIP</Col>
                    </Row>
                  </div>
                </CardBody>
                {/*
                <CardFooter>
                  <div className="button-container">
                    <Button className="btn-icon btn-round" color="facebook">
                      <i className="fab fa-facebook" />
                    </Button>
                    <Button className="btn-icon btn-round" color="twitter">
                      <i className="fab fa-twitter" />
                    </Button>
                    <Button className="btn-icon btn-round" color="google">
                      <i className="fab fa-google-plus" />
                    </Button>
                  </div>
                </CardFooter>
                */}
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

UserProfile.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(
  mapStateToProps,
  null,
)(UserProfile);
