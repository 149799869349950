import React from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';

// core components
import AdminNavbar from './../components/dashboard/navbar/Navbar.js';
import Footer from './../components/dashboard/footer/Footer.js';
import Sidebar from './../components/dashboard/sidebar/Sidebar.js';
//import FixedPlugin from "./../components/FixedPlugin/FixedPlugin.jsx";

import routes from './../admin-routes.js';

import logo from './../assets/ddelays.png';

import PrivateRoute from './../components/private-route/PrivateRoute';
import AuthRoute from './../components/private-route/AuthRoute';

import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import './../assets/dashboard/scss/black-dashboard-react.scss';
import './../assets/dashboard/demo/demo.css';
import './../assets/dashboard/css/nucleo-icons.css';

var ps;

class DashboardLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: 'blue',
      sidebarOpened: document.documentElement.className.indexOf('nav-open') !== -1,
    };
  }

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      document.documentElement.className += ' perfect-scrollbar-on';
      document.documentElement.classList.remove('perfect-scrollbar-off');
      ps = new PerfectScrollbar(this.refs.mainPanel, { suppressScrollX: true });
      let tables = document.querySelectorAll('.table-responsive');
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
      document.documentElement.className += ' perfect-scrollbar-off';
      document.documentElement.classList.remove('perfect-scrollbar-on');
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === 'PUSH') {
      if (navigator.platform.indexOf('Win') > -1) {
        let tables = document.querySelectorAll('.table-responsive');
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  // this function opens and closes the sidebar on small devices
  toggleSidebar = e => {
    document.documentElement.classList.toggle('nav-open');
    this.setState({ sidebarOpened: !this.state.sidebarOpened });
  };

  toggleSidebar2 = (e, value) => {
    if (value === undefined || value === 'active') e.preventDefault();
    if (this.state.sidebarOpened) {
      document.documentElement.classList.toggle('nav-open');
      this.setState({ sidebarOpened: !this.state.sidebarOpened });
    }
  };
  getRoutes = routes => {
    return routes.map((prop, key) => {
      const { pathname } = this.props.location;
      const { user } = this.props.auth;

      if (pathname === '/dashboard/' || pathname === '/dashboard') {
        window.location.replace('/dashboard/home');
        return <Redirect push to="/dashboard/home" />;
      }
      if (prop.layout === '/dashboard') {
        let additional_params = {};
        if (prop.exact) {
          additional_params['exact'] = true;
        }
        additional_params['role_id'] = prop.role_id ? prop.role_id : 1;

        //console.log(additional_params['role_id'], user.role_id, 'A');
        if (additional_params['role_id'] >= 1) {
          if (!user)
            // No Access
            return <Redirect key={key} to="/dashboard/home" />;
          else if (user.role_id < additional_params['role_id'])
            // Not enough access
            return <Redirect key={key} to="/dashboard/home" />;
        }
        if (prop.public) {
          return (
            <Route
              path={prop.layout + prop.path}
              component={withRouter(prop.component)}
              key={key}
              {...additional_params}
            />
          );
        } else if (prop.auth) {
          return (
            <AuthRoute
              path={prop.layout + prop.path}
              component={withRouter(prop.component)}
              key={key}
              {...additional_params}
            />
          );
        }

        return (
          <PrivateRoute
            path={prop.layout + prop.path}
            component={withRouter(prop.component)}
            key={key}
            {...additional_params}
          />
        );
      } else {
        return null;
      }
    });
  };
  handleBgClick = color => {
    this.setState({ backgroundColor: color });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (!routes[i].display) continue;
      if (
        (routes[i].exact && this.props.location.pathname === routes[i].layout + routes[i].path) ||
        this.props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
    return 'dDelays';
  };
  render() {
    const { user } = this.props.auth;

    return (
      <>
        <div className="black_dashboard wrapper">
          <Sidebar
            {...this.props}
            routes={routes}
            bgColor={this.state.backgroundColor}
            logo={{
              outterLink: 'https://www.google.com/',
              text: user.name.split(' ')[0],
              imgSrc: logo,
            }}
            className="nav-open"
            toggleSidebar={this.toggleSidebar2}
            sidebarOpened={this.state.sidebarOpened}
          />
          <div className="main-panel" ref="mainPanel" data={this.state.backgroundColor}>
            <AdminNavbar
              {...this.props}
              brandText={this.getBrandText(this.props.location.pathname, this.props.exact)}
              toggleSidebar={this.toggleSidebar}
              sidebarOpened={this.state.sidebarOpened}
            />
            <Switch>{this.getRoutes(routes)}</Switch>
            <ToastContainer />
            <Footer fluid />
            {
              // we don't want the Footer to be rendered on map page
              //this.props.location.pathname.indexOf('maps') !== -1 ? null : <Footer fluid />
            }
          </div>
        </div>
        {/*
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />*/}
      </>
    );
  }
}

DashboardLayout.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(
  mapStateToProps,
  null,
)(DashboardLayout);
