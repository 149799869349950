function Translation(props) {
  const { lang, file } = props.match.params;

  if (lang && file) {
    const data = require(`./assets/locales/${lang}/${file}`);
    return JSON.stringify(data);
  }
  return '';
}

export default Translation;
