import React from 'react';

// reactstrap components
import axios from 'axios';
import { toast } from 'react-toastify';

import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import {
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Row,
  Button,
  CardFooter,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';

class AddPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      plans: {},
      new_plan: {
        name: '',
        validity: 1,
        amount: {
          inr: 1,
          usd: 1,
        },
      },
    };
    this.onEditPlan.bind(this);
    this.onDeletePlan.bind(this);
    this.onSavePlan.bind(this);
    this.onAddPlan.bind(this);
    this.handleChange.bind(this);
  }

  handleChange(e, plan_idx, type) {
    if (this.state.plans.length <= plan_idx) {
      return;
    }
    var plans = this.state.plans;
    var plan;
    if (plan_idx === -1) plan = this.state.new_plan;
    else plan = plans[plan_idx];
    var amount = 7000.0;
    if (e.target.value === '') {
      e.target.value = 0;
    }
    switch (type) {
      case 'inr':
        amount = parseFloat(e.target.value).toFixed(2);
        if (amount <= 0) {
          toast.error('Invalid amount (INR)', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          amount = 7000.0;
        }
        plan.amount.inr = amount;
        break;
      case 'usd':
        amount = parseFloat(e.target.value).toFixed(2);
        if (amount <= 0) {
          toast.error('Invalid amount (USD)', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          amount = 100.0;
        }
        plan.amount.usd = amount;
        break;
      case 'validity':
        var validity = parseInt(e.target.value);
        if (validity <= 0 || validity >= 365) {
          toast.error('Invalid Validity', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          validity = 1;
        }
        plan.validity = validity;
        break;
      case 'name':
        plan.name = e.target.value;
        break;
      default:
        return;
    }
    if (plan_idx === -1) this.setState({ new_plan: plan });
    else this.setState({ plans: plans });
  }

  onSavePlan(plan_idx) {
    if (this.state.plans.length <= plan_idx) {
      return;
    }
    var data = this.state.plans[plan_idx];
    var plans = this.state.plans;
    axios
      .post('/api/admin/plan/save', data)
      .then(res => {
        if (res.data.success === 0) {
          toast.warn('Failed to save, please check the values.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          data['edit'] = false;
        }
        this.setState({
          plans: plans,
        });
      })
      .catch(err => {
        toast.error('Cannot Save. Please try again or refresh the page', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });

    //this.state.plans['edit'] = false;
  }

  onAddPlan() {
    var data = this.state.new_plan;
    axios
      .post('/api/admin/plan/add', data)
      .then(res => {
        if (res.data.success === 0) {
          toast.warn('Failed to add new plan, please check the values.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          var plans = this.state.plans;
          plans.push(res.data.plan);

          this.setState({
            plans: plans,
            new_plan: {
              name: '',
              validity: 1,
              amount: {
                inr: 1,
                usd: 1,
              },
            },
          });
          toast.success('New Plan Added', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(err => {
        toast.error('Cannot Save. Please try again or refresh the page', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });

    //this.state.plans['edit'] = false;
  }

  onEditPlan(plan_idx) {
    if (this.state.plans.length <= plan_idx) {
      return;
    }
    var plans = this.state.plans;
    plans[plan_idx]['edit'] = true;
    this.setState({ plans: plans });
  }

  onDeletePlan(plan_idx) {
    if (this.state.plans.length <= plan_idx) {
      return;
    }
    var plans = this.state.plans;
    axios
      .post('/api/admin/plan/remove', plans)
      .then(res => {
        if (res.data.success === 0) {
          toast.warn('Failed to remove plan.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          var plans = this.state.plans;
          plans.splice(plan_idx, 1);

          this.setState({
            plans: plans,
          });
          toast.success('Plan Removed', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(err => {
        toast.error('Cannot remove. Please try again or refresh the page', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  }
  componentDidMount() {
    axios
      .get('/api/plans/all')
      .then(res => {
        this.setState({
          loading: false,
          plans: res.data.plan,
        });
      })
      .catch(err => {
        console.log(err.response);
      });
  }

  render() {
    if (this.state.loading) {
      return <div className="content">Loading...</div>;
    }

    return (
      <div className="content">
        <h3 className="text-center">Add new Plans</h3>
        <Row>
          <Col sm={{ offset: 1, size: '10' }} xs="12" l={{ offset: 4, size: 8 }}>
            <Card>
              <CardHeader tag="h3" className="text-center">
                <FormGroup>
                  <Row>
                    <Col sm="2" md="2">
                      <Label for="planName">Name:</Label>
                    </Col>
                    <Col sm="10" md="10">
                      <Input
                        type="text"
                        name="planName"
                        id="planName"
                        onChange={e => {
                          this.handleChange(e, -1, 'name');
                        }}
                        value={this.state.new_plan.name}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </CardHeader>
              <CardBody className="text-center">
                <CardTitle>
                  <Row>
                    <Col sm="2" md="2">
                      <Label for="planValidity">Validity (in Days):</Label>
                    </Col>
                    <Col sm="10" md="10">
                      <Input
                        type="text"
                        name="planValidity"
                        id="planValidity"
                        onChange={e => {
                          this.handleChange(e, -1, 'validity');
                        }}
                        value={this.state.new_plan.validity}
                      />
                    </Col>
                  </Row>
                </CardTitle>
                <Row>
                  <Col sm="2" md="2">
                    <Label for="planUSD">Amount (in USD):</Label>
                  </Col>
                  <Col sm="4" md="4">
                    <Input
                      type="text"
                      name="planUSD"
                      id="planUSD"
                      onChange={e => {
                        this.handleChange(e, -1, 'usd');
                      }}
                      value={this.state.new_plan.amount.usd}
                    />
                  </Col>
                  <Col sm="2" md="2">
                    <Label for="planINR">Amount (in INR):</Label>
                  </Col>
                  <Col sm="4" md="4">
                    <Input
                      type="text"
                      name="planINR"
                      id="planINR"
                      onChange={e => {
                        this.handleChange(e, -1, 'inr');
                      }}
                      value={this.state.new_plan.amount.inr}
                    />
                  </Col>
                </Row>
              </CardBody>
              <CardFooter style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <Button onClick={() => this.onAddPlan()}>Add new Plan</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <h3 className="text-center">Existing Plans</h3>
        <Row>
          {this.state.plans.map((plan, index) => {
            if (plan.edit) {
              return (
                <Col sm="6" xs="12" md="4" key={index}>
                  <Card>
                    <CardHeader tag="h3" className="text-center">
                      <FormGroup>
                        <Row>
                          <Col sm="2" md="2">
                            <Label for="planName">Name:</Label>
                          </Col>
                          <Col sm="10" md="10">
                            <Input
                              type="text"
                              name="planName"
                              id="planName"
                              onChange={e => {
                                this.handleChange(e, index, 'name');
                              }}
                              value={plan.name}
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                    </CardHeader>
                    <CardBody className="text-center">
                      <CardTitle>
                        <Row>
                          <Col sm="2" md="2">
                            <Label for="planValidity">Validity (in Days):</Label>
                          </Col>
                          <Col sm="10" md="10">
                            <Input
                              type="text"
                              name="planValidity"
                              id="planValidity"
                              onChange={e => {
                                this.handleChange(e, index, 'validity');
                              }}
                              value={plan.validity}
                            />
                          </Col>
                        </Row>
                      </CardTitle>
                      <Row>
                        <Col sm="2" md="2">
                          <Label for="planUSD">Amount (in USD):</Label>
                        </Col>
                        <Col sm="4" md="4">
                          <Input
                            type="text"
                            name="planUSD"
                            id="planUSD"
                            onChange={e => {
                              this.handleChange(e, index, 'usd');
                            }}
                            value={plan.amount.usd}
                          />
                        </Col>
                        <Col sm="2" md="2">
                          <Label for="planINR">Amount (in INR):</Label>
                        </Col>
                        <Col sm="4" md="4">
                          <Input
                            type="text"
                            name="planINR"
                            id="planINR"
                            onChange={e => {
                              this.handleChange(e, index, 'inr');
                            }}
                            value={plan.amount.inr}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <Button onClick={() => this.onSavePlan(index)}>Save</Button>
                      <Button
                        onClick={() => this.onDeletePlan(index)}
                        color="danger"
                        className="float-right"
                      >
                        Delete
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              );
            } else {
              return (
                <Col sm="6" xs="12" md="4" key={index}>
                  <Card>
                    <CardHeader tag="h3" className="text-center">
                      {plan.name}
                    </CardHeader>
                    <CardBody className="text-center">
                      <CardTitle>Validity: {plan.validity} Days</CardTitle>
                      <CardText>
                        Price:
                        <br /> USD: {plan.amount.usd} $, INR: {plan.amount.inr} ₹<br />
                      </CardText>
                    </CardBody>
                    <CardFooter>
                      <Button onClick={() => this.onEditPlan(index)}>Edit</Button>
                      <Button
                        onClick={() => this.onDeletePlan(index)}
                        color="danger"
                        className="float-right"
                      >
                        Delete
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              );
            }
          })}
        </Row>
      </div>
    );

    //return <div className="content">Loading Done</div>;
  }
}

AddPlan.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(
  mapStateToProps,
  null,
)(AddPlan);
