import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Route, BrowserRouter as Router, Switch, withRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';

// import i18n
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

import { Provider } from 'react-redux';
import store from './redux/store';

import Notfound from './views/404';
import LandingPage from './views/LandingPage';
import Translation from './Translation';
import PrivateRoute from './components/private-route/PrivateRoute';
import AuthRoute from './components/private-route/AuthRoute';
import DashboardLayout from './views/DashboardLayout';

import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from './redux/action/authActions';
import { Spinner } from 'reactstrap';

import ReactGA from 'react-ga';

let userId_Tracking = '-1';
// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  userId_Tracking = decoded.user_id;
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = './';
  }
}
// GA Setup
const trackingId = 'UA-150644947-1';
ReactGA.initialize(trackingId);
ReactGA.set({
  id: userId_Tracking,
  userId: userId_Tracking,
});
ReactGA.pageview(window.location.pathname + window.location.search);

// Listen to history pageview
const history = createBrowserHistory();

// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const suspense = <Spinner style={{ width: '3rem', height: '3rem' }} />;

const routing = (
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={suspense}>
        <Router>
          <Switch>
            <AuthRoute exact path="/" component={withRouter(LandingPage)} />
            {/*
                <Route path="/users" component={Users} />
                <Route path="/contact" component={Contact} />
                */}
            <Route path="/locales/:lang/:file" component={withRouter(Translation)} />

            <PrivateRoute role_id={1} path="/dashboard" component={withRouter(DashboardLayout)} />
            <Route component={Notfound} />
          </Switch>
        </Router>
      </Suspense>
    </I18nextProvider>
  </Provider>
);

ReactDOM.render(routing, document.getElementById('root'));

serviceWorker.unregister();
