import React from 'react';
import axios from 'axios';
import { Input, FormGroup, Form, Col, Row } from 'reactstrap';
import { Card, CardText, CardBody, CardTitle, Button } from 'reactstrap';
import Services from '../Services';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

class CallbackPage extends React.Component {
  constructor(props) {
    super(props);
    this.checkStatus = this.checkStatus.bind(this);
    this.clickSubmit = this.clickSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.state = {
      input: '',
      status: -1,
      orderId: '',
      subscription: {},
    };
  }

  onChange(e) {
    this.setState({
      input: e.target.value,
    });
  }

  clickSubmit() {
    this.checkStatus(this.state.input);
    this.setState({
      orderId: this.state.input,
    });
  }

  checkStatus(orderId) {
    const userData = {
      id: orderId,
      user_id: this.props.auth.user.id,
    };
    axios
      .post('/api/payments/paytm/check_status', userData)
      .then(res => {
        this.setState({
          status: res.data.status,
          subscription: [res.data.subscription],
        });
      })
      .catch(err => {
        console.log('Reducer Error', err);
        console.log(err.response.data);
      });
  }

  componentDidMount() {
    var orderId = '';
    if (this.props && this.props.match && this.props.match.params && this.props.match.params.id) {
      orderId = this.props.match.params.id;
      this.setState({
        orderId: this.props.match.params.id,
      });
    }
    if (orderId !== '') {
      this.checkStatus(orderId);
    }
  }

  render() {
    return (
      <div className="content">
        <Row>
          <Col md="6">
            <Card>
              <CardBody>
                <CardTitle>Please input your OrderID</CardTitle>

                <Form>
                  <FormGroup>
                    <Input
                      type="text"
                      name="orderID"
                      value={this.state.input}
                      onChange={this.onChange}
                    />
                  </FormGroup>
                </Form>

                <Button onClick={this.clickSubmit}>Check Status</Button>
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card>
              <CardBody>
                <CardTitle>Order Status</CardTitle>

                <CardText>
                  {this.state.status === -1 && 'Waiting for OrderID to be submitted.'}
                  {this.state.status !== -1 && (
                    <>
                      <span>OrderID: {this.state.orderId}</span>
                      <br />
                      Status:{' '}
                    </>
                  )}
                  {this.state.status === 0 && 'Order not found'}
                  {this.state.status === 1 && 'Payment Pending'}
                  {this.state.status === 2 &&
                    'Order successful. Please check below table for more information'}
                  {this.state.status === 3 && 'Payment Failed'}
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {this.state.status === 2 && (
          <Services message="Order Status" subscription={this.state.subscription} />
        )}
      </div>
    );
  }
}

CallbackPage.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(withRouter(CallbackPage));
