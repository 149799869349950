//import Dashboard from "./views/Dashboard/Dashboard.js";
import Dashboard from './views/Dashboard/Dashboard';
import UserProfile from './views/Dashboard/UserProfile';
import PayTM from './views/Dashboard/Payments/PayTM';
import PayTMCallback from './components/dashboard/payments/paytm_callback';
import SubscriptionList from './views/Dashboard/Subscription/SubscriptionList';
import Subscription from './views/Dashboard/Subscription/BuySubscription';
import { faList, faTasks, faCartPlus } from '@fortawesome/free-solid-svg-icons';

// Admin
import AdminAddPlan from './views/Dashboard/Admin/Plan/AddPlan';
import AdminModifySubscription from './views/Dashboard/Admin/Subscription/Modify';
/*
import Icons from "views/Icons.jsx";
import Map from "views/Map.jsx";
import Notifications from "views/Notifications.jsx";
import Rtl from "views/Rtl.jsx";
import TableList from "views/TableList.jsx";
import Typography from "views/Typography.jsx";

*/
var routes = [
  {
    path: '/home',
    name: 'Dashboard',
    rtlName: 'لوحة القيادة',
    icon: 'tim-icons icon-chart-pie-36',
    component: Dashboard,
    layout: '/dashboard',
    exact: true,
  },
  {
    path: '/user-profile',
    name: 'User Profile',
    rtlName: 'ملف تعريفي للمستخدم',
    icon: 'tim-icons icon-single-02',
    component: UserProfile,
    layout: '/dashboard',
  },

  /* Not Visble Start */
  {
    path: '/payment/paytm',
    name: 'PayTM',
    icon: 'tim-icons icon-single-02',
    component: PayTM,
    layout: '/dashboard',
    display: false,
    exact: true,
  },
  {
    path: '/payment/paytm/status/:id',
    name: 'PayTM Order Status',
    icon: 'tim-icons icon-single-02',
    component: PayTMCallback,
    layout: '/dashboard',
    display: false,
    exact: true,
  },
  /* Not Visble End */
  {
    path: '/subscription/add',
    name: 'Order new service',
    fontAwesome: faCartPlus,
    component: Subscription,
    layout: '/dashboard',
  },
  {
    path: '/subscription/list',
    name: 'My Subscriptions',
    fontAwesome: faList,
    component: SubscriptionList,
    layout: '/dashboard',
  },

  {
    path: '/payment/paytm/status',
    name: 'Order Status',
    //icon: 'tim-icons icon-single-02',
    fontAwesome: faTasks,
    component: PayTMCallback,
    layout: '/dashboard',
    exact: true,
  },
  // Admin Roles
  {
    path: '/admin/plans/add',
    name: 'Add Plans',
    //icon: 'tim-icons icon-single-02',
    fontAwesome: faTasks,
    component: AdminAddPlan,
    layout: '/dashboard',
    exact: true,
    role_id: 10,
  },
  {
    path: '/admin/subscriptions/modify',
    name: 'Modify Subscriptions',
    //icon: 'tim-icons icon-single-02',
    fontAwesome: faCartPlus,
    component: AdminModifySubscription,
    layout: '/dashboard',
    exact: true,
    role_id: 10,
  },
  /*
  {
    path: "/icons",
    name: "Icons",
    rtlName: "الرموز",
    icon: "tim-icons icon-atom",
    component: Icons,
    layout: "/admin"
  },
  {
    path: "/map",
    name: "Map",
    rtlName: "خرائط",
    icon: "tim-icons icon-pin",
    component: Map,
    layout: "/admin"
  },
  {
    path: "/notifications",
    name: "Notifications",
    rtlName: "إخطارات",
    icon: "tim-icons icon-bell-55",
    component: Notifications,
    layout: "/admin"
  },
  {
    path: "/tables",
    name: "Table List",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-puzzle-10",
    component: TableList,
    layout: "/admin"
  },
  {
    path: "/typography",
    name: "Typography",
    rtlName: "طباعة",
    icon: "tim-icons icon-align-center",
    component: Typography,
    layout: "/admin"
  },
  {
    path: "/rtl-support",
    name: "RTL Support",
    rtlName: "ار تي ال",
    icon: "tim-icons icon-world",
    component: Rtl,
    layout: "/rtl"
  }
  */
];
export default routes;
