import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import jwt_decode from 'jwt-decode';

var AuthToken = "";

var backendConfig = {
  loadPath: '/api/translations/{{lng}}/{{ns}}.json',
  addPath: '/dummy',
};

if (localStorage.jwtToken) {
  AuthToken = localStorage.jwtToken;
  const decoded = jwt_decode(AuthToken);
  // For Admin, Add Config
  if (decoded.role_id >= 10) {
    backendConfig = {...backendConfig, ...{
      addPath: '/api/translations/add/{{lng}}/{{ns}}.json',
      withCredentials: true,
      customHeaders: {
        Authorization: AuthToken,
      }
    }}
  }
}

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: true,
    lng: 'en',
    fallbackLng: 'en', // use en if detected lng is not available
    saveMissing: true, // send not translated keys to endpoint
    keySeparator: true, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: backendConfig
  });

export default i18n;
