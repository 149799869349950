import React from 'react';

// reactstrap components
import { Button, Container } from 'reactstrap';

//import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import RegisterModal from '../../RegisterModal';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import axios from 'axios';
import { toast } from 'react-toastify';

// core components

function Header(props) {
  let pageHeader = React.createRef();
  const [subscriptions, setSubscriptions] = React.useState('Loading...');
  const { user } = props.auth;

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform = 'translate3d(0,' + windowScrollTop + 'px,0)';
      };
      window.addEventListener('scroll', updateScroll);
      return function cleanup() {
        window.removeEventListener('scroll', updateScroll);
      };
    }
  });

  React.useEffect(() => {
    axios
      .get('/api/public/getAllSubs')
      .then(res => {
        if (parseInt(res.data.value) > 0) {
          setSubscriptions(res.data.value);
        } else {
          setSubscriptions('Error');
        }
      })
      .catch(err => {
        toast.error('Cannot get Subscription Data', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  }, []);

  return (
    <>
      <div
        style={{
          //'background': '#43cea2',  /* fallback for old browsers */
          //'background': '-webkit-linear-gradient(to right, #185a9d, #43cea2)',  /* Chrome 10-25, Safari 5.1-6 */
          background:
            'linear-gradient(to right, #185a9d, #43cea2)' /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */,
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="filter" />
        <Container>
          <div className="motto text-center">
            <h1>Welcome {props.auth.isAuthenticated && `${user.name}`}</h1>
            <h3>Total Subscriptions: {subscriptions}</h3>
            {props.auth.isAuthenticated === true && (
              <>
                <h3>Please Proceed to Dashboard to know more</h3>
                <Link to="/dashboard" key="new">
                  <Button className="btn-round mr-1" color="neutral" type="button" outline>
                    Dashboard
                  </Button>
                </Link>
              </>
            )}
            {props.auth.isAuthenticated === false && (
              <>
                <h3>Please Register to get access to dashboard</h3>
                <RegisterModal>
                  <Button className="btn-round mr-1" color="neutral" type="button" outline>
                    Register
                  </Button>
                </RegisterModal>
                {/*
                <LoginModal>
                  <Button className="btn-round" color="neutral" type="button" outline>
                    Login
                  </Button>
                </LoginModal>
                */}
              </>
            )}
          </div>
        </Container>
      </div>
    </>
  );
}

Header.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(Header);
