import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

class PaymentMethodModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      className: props.className ? props.className : '',
    };
    this.toggle = this.toggle.bind(this);
    this.paytm = this.paytm.bind(this);
    this.paypal = this.paypal.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  paytm() {
    this.props.callback.proceedPayment('paytm', this.props.plan_id, this.props.amount.inr);
  }

  paypal() {
    this.props.callback.proceedPayment('paypal', this.props.plan_id, this.props.amount.usd);
  }

  render() {
    return (
      <>
        <Button onClick={this.toggle}>{this.props.children}</Button>

        <Modal isOpen={this.state.modal} className={this.state.className}>
          <ModalHeader toggle={this.toggle}>Choose Payment Method</ModalHeader>
          <ModalBody className="text-center">
            <div>
              Please choose from payment method mentioned below. If you do not have access to any of
              these payment gateway, Please contact developer on Discord Channel
            </div>
            <Button onClick={this.paytm}>PayTM</Button>
            <Button onClick={this.paypal}>PayPal</Button>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

PaymentMethodModal.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(
  mapStateToProps,
  null,
)(withRouter(PaymentMethodModal));
