import React from 'react';
import { useTranslation } from 'react-i18next';

// reactstrap components
import { Container, Row, Col } from 'reactstrap';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { toast } from 'react-toastify';

// core components
import LandingNavbar from '../components/landing/navbar/Navbar.js';
import Header from '../components/landing/header/Header.js';
import Footer from '../components/landing/footer/Footer.js';

import '../assets/landing/css/bootstrap.min.css';
import '../assets/landing/scss/paper-kit.scss';
import '../assets/landing/main.css';

function LandingPage() {
  const { t } = useTranslation('landing' /*{ useSuspense: false }*/);
  //const t = () => {};
  document.documentElement.classList.remove('nav-open');
  React.useEffect(() => {
    document.body.classList.add('profile-page');
    return function cleanup() {
      document.body.classList.remove('profile-page');
    };
  });

  toast.configure();
  return (
    <>
      <div className="landingPage-layout">
        <ToastContainer />
        <LandingNavbar />
        <Header />
        <div className="main">
          <div className="section text-center">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h2 className="title">{t(`title`, 'Anti NoDelays')}</h2>
                  <h5 className="description">
                    {t(
                      `intro`,
                      `Welcome to Anti NoDelays (dDelays). Please Register to know more about the pricing details`,
                    )}
                  </h5>
                  <br />
                  {/*
                <Button
                  className="btn-round"
                  color="info"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  {t(`btn_detail_text`, 'See Details')}
                </Button>*/}
                </Col>
              </Row>
              <br />
              <br />
              <Row>
                <Col md="3">
                  <div className="info">
                    <div className="icon icon-info">
                      <i className="nc-icon nc-album-2" />
                    </div>
                    <div className="description">
                      <h4 className="info-title">Anti NoDelays</h4>
                      <p className="description">Restrict players that use NoDelays GRF</p>
                      {/*
                    <Button className="btn-link" color="info" href="#pablo">
                      See more
                    </Button>*/}
                    </div>
                  </div>
                </Col>
                <Col md="3">
                  <div className="info">
                    <div className="icon icon-info">
                      <i className="nc-icon nc-bulb-63" />
                    </div>
                    <div className="description">
                      <h4 className="info-title">Always Updating</h4>
                      <p>dDelays will always updated for new skills/items</p>
                    </div>
                  </div>
                </Col>
                <Col md="3">
                  <div className="info">
                    <div className="icon icon-info">
                      <i className="nc-icon nc-chart-bar-32" />
                    </div>
                    <div className="description">
                      <h4 className="info-title">Statistics</h4>
                      <p>
                        Get insight of how many players are online and beautiful charts from
                        NoDelays Data(WIP)
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md="3">
                  <div className="info">
                    <div className="icon icon-info">
                      <i className="nc-icon nc-sun-fog-29" />
                    </div>
                    <div className="description">
                      <h4 className="info-title">Easy to Install</h4>
                      <p>Just add the plugin and you are good to go.</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

LandingPage.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(LandingPage);
