/*eslint-disable*/
import React from 'react';
import { NavLink, Link } from 'react-router-dom';
// nodejs library to set properties for components
import { PropTypes } from 'prop-types';

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';

// reactstrap components
import { Nav } from 'reactstrap';

import { connect } from 'react-redux';
import { logoutUser } from '../../../redux/action/authActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    this.onLogoutClick.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(layout, routeName, exact) {
    if (exact) {
      return this.props.location.pathname === layout + routeName ? 'active' : '';
    }
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  }
  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }
  linkOnClick = () => {
    document.documentElement.classList.remove('nav-open');
  };

  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };
  render() {
    const { bgColor, routes, rtlActive, logo } = this.props;
    const { user } = this.props.auth;
    let logoImg = null;
    let logoText = null;

    if (logo !== undefined) {
      if (logo.outterLink !== undefined) {
        logoImg = (
          <a
            href={logo.outterLink}
            className="simple-text logo-mini"
            target="_blank"
            onClick={this.props.toggleSidebar}
          >
            <div className="logo-img">
              <img src={logo.imgSrc} alt="react-logo" />
            </div>
          </a>
        );
        logoText = (
          <a
            href={logo.outterLink}
            className="simple-text logo-normal"
            target="_blank"
            onClick={this.props.toggleSidebar}
          >
            {logo.text}
          </a>
        );
      } else {
        logoImg = (
          <Link
            to={logo.innerLink}
            className="simple-text logo-mini"
            onClick={this.props.toggleSidebar}
          >
            <div className="logo-img">
              <img src={logo.imgSrc} alt="react-logo" />
            </div>
          </Link>
        );
        logoText = (
          <Link
            to={logo.innerLink}
            className="simple-text logo-normal"
            onClick={this.props.toggleSidebar}
          >
            {logo.text}
          </Link>
        );
      }
    }
    return (
      <div className={this.props.sidebarOpened ? 'sidebar nav-open' : 'sidebar'} data={bgColor}>
        <div
          className={
            this.props.sidebarOpened ? 'sidebar-wrapper main-panel mt-5' : 'sidebar-wrapper'
          }
          ref="sidebar"
        >
          {logoImg !== null || logoText !== null ? (
            <div className="logo">
              {logoImg}
              {logoText}
            </div>
          ) : null}
          <Nav>
            {routes.map((prop, key) => {
              // Redirect
              if (prop.redirect) return null;
              let role_id = prop.role_id ? prop.role_id : 1;
              // No Access
              if (role_id >= 1 && (!user || user.role_id < role_id)) return null;
              if ('display' in prop && prop.display === false) return null;

              var active = this.activeRoute(prop.layout, prop.path, prop.exact);
              return (
                <li className={active + (prop.pro ? ' active-pro' : '')} key={key}>
                  <NavLink
                    to={prop.layout + prop.path}
                    className="nav-link"
                    activeClassName="active"
                    onClick={e => this.props.toggleSidebar(e, active)}
                  >
                    {prop.fontAwesome && (
                      <i>
                        <FontAwesomeIcon icon={prop.fontAwesome} />
                      </i>
                    )}
                    {prop.icon && <i className={prop.icon} />}
                    <p>{rtlActive ? prop.rtlName : prop.name}</p>
                  </NavLink>
                </li>
              );
            })}
            <li>
              <NavLink to="#" className="nav-link" onClick={this.onLogoutClick}>
                <i>
                  <FontAwesomeIcon icon={faSignOutAlt} />
                </i>
                <p> Logout</p>
              </NavLink>
            </li>
          </Nav>
        </div>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  rtlActive: false,
  bgColor: 'primary',
  routes: [{}],
};

Sidebar.propTypes = {
  // if true, then instead of the routes[i].name, routes[i].rtlName will be rendered
  // insde the links of this component
  rtlActive: PropTypes.bool,
  bgColor: PropTypes.oneOf(['primary', 'blue', 'green']),
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the text of the logo
    text: PropTypes.node,
    // the image src of the logo
    imgSrc: PropTypes.string,
  }),
};

Sidebar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(
  mapStateToProps,
  { logoutUser },
)(Sidebar);
