import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { registerUser } from '../redux/action/authActions';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, Col, Input, FormGroup, Label, FormFeedback } from 'reactstrap';

class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      email: '',
      password: '',
      password2: '',
      mobile_no: '',
      errors: {},
      modal: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  componentWillUpdate(nextProps) {
    if (this.props.errors !== nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/dashboard');
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const newUser = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2,
      mobile_no: this.state.mobile_no,
    };
    this.props.registerUser(newUser, this.props.history);
  };
  render() {
    const { errors } = this.state;
    return (
      <>
        <span onClick={this.toggle}>{this.props.children}</span>
        <div>
          <Form noValidate onSubmit={this.onSubmit}>
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.state.className}>
              <ModalHeader toggle={this.toggle}>Sign In</ModalHeader>
              <ModalBody>
                <Col>
                  <FormGroup>
                    <Label for="name">Name</Label>
                    <Input
                      onChange={this.onChange}
                      value={this.state.name}
                      error={errors.name}
                      id="name"
                      type="text"
                      invalid={errors.name ? true : false}
                    />
                    <FormFeedback>{errors.name}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      onChange={this.onChange}
                      value={this.state.email}
                      error={errors.email}
                      id="email"
                      type="email"
                      invalid={errors.email ? true : false}
                    />
                    <FormFeedback>{errors.email}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="mobile_no">Mobile Number</Label>
                    <Input
                      onChange={this.onChange}
                      value={this.state.mobile_no}
                      error={errors.mobile}
                      id="mobile_no"
                      type="mobile_no"
                      invalid={errors.mobile ? true : false}
                    />
                    <FormFeedback>{errors.mobile}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="password">Password</Label>
                    <Input
                      onChange={this.onChange}
                      value={this.state.password}
                      error={errors.password}
                      id="password"
                      type="password"
                      invalid={errors.password ? true : false}
                    />
                    <FormFeedback>{errors.password}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="password2">Confirm Password</Label>
                    <Input
                      onChange={this.onChange}
                      value={this.state.password2}
                      error={errors.password2}
                      id="password2"
                      type="password"
                      invalid={errors.password2 ? true : false}
                    />
                    <FormFeedback>{errors.password2}</FormFeedback>
                  </FormGroup>
                </Col>
              </ModalBody>
              <ModalFooter style={{ padding: '1rem' }}>
                <Button
                  type="submit"
                  color="primary"
                  className="btn btn-large waves-effect waves-light hoverable blue accent-3"
                  onClick={this.onSubmit}
                >
                  Submit
                </Button>{' '}
                <Button color="secondary" onClick={this.toggle}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </Form>
        </div>
      </>
    );
  }
}
Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(
  mapStateToProps,
  { registerUser },
)(withRouter(Register));
