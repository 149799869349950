import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Col, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../redux/action/authActions';

import { withRouter } from 'react-router-dom';

class LoginModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errors: {},
      modal: false,
      className: props.className ? props.className : '',
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  componentWillUpdate(nextProps) {
    if (
      this.props.auth.isAuthenticated !== nextProps.auth.isAuthenticated &&
      nextProps.auth.isAuthenticated
    ) {
      this.props.history.push('/dashboard'); // push user to dashboard when they login
    } else if (this.props.errors !== nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/');
    }
  }
  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };
  onSubmit = e => {
    e.preventDefault();
    const userData = {
      email: this.state.email,
      password: this.state.password,
    };
    this.props.loginUser(userData); // since we handle the redirect within our component, we don't need to pass in this.props.history as a parameter
  };

  render() {
    const { errors } = this.state;

    return (
      <>
        <span onClick={this.toggle}>{this.props.children}</span>
        <div>
          <Form noValidate onSubmit={this.onSubmit}>
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.state.className}>
              <ModalHeader toggle={this.toggle}>Sign In</ModalHeader>
              <ModalBody>
                <Col>
                  <FormGroup>
                    <Label>Email</Label>
                    <Input
                      onChange={this.onChange}
                      value={this.state.email}
                      error={errors.email}
                      type="email"
                      name="email"
                      id="email"
                      placeholder="myemail@email.com"
                      invalid={errors.email || errors.emailnotfound ? true : false}
                    />
                    <FormFeedback>
                      {errors.email}
                      {errors.emailnotfound}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="password">Password</Label>
                    <Input
                      onChange={this.onChange}
                      value={this.state.password}
                      error={errors.password}
                      type="password"
                      name="password"
                      id="password"
                      placeholder="********"
                      invalid={errors.password || errors.passwordincorrect ? true : false}
                    />
                    <FormFeedback>
                      {errors.password}
                      {errors.passwordincorrect}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </ModalBody>
              <ModalFooter style={{ padding: '1rem' }}>
                <Button type="submit" color="primary" onClick={this.onSubmit}>
                  Submit
                </Button>{' '}
                <Button color="secondary" onClick={this.toggle}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </Form>
        </div>
      </>
    );
  }
}

LoginModal.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(
  mapStateToProps,
  { loginUser },
)(withRouter(LoginModal));
