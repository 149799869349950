/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Input, Form, FormFeedback, Label, Row, Col } from 'reactstrap';

import axios from 'axios';
import { toast } from 'react-toastify';

const VerifyPaymentModal = props => {
  var { payment } = props;

  const [modal, setModal] = useState(props.modalShow ? props.modalShow : false);
  const [edit, setEdit] = useState(false);
  const [details, setDetails] = useState({
    ...payment,
  });
  const [errors, setErrors] = useState({ status: '' });

  const statusTypes = ['Initiated', 'Success', 'Failure'];

  const toggle = () => {
    if (props.callback) {
      props.callback(props.idx, details.status);
    }
    setModal(!modal);
  };

  const toggleEdit = () => {
    setEdit(!edit);
  };

  const toggleSave = () => {
    const data = {
      id: payment._id,
      details: { ...details },
    };
    axios
      .post('/api/admin/subscription/edit', data)
      .then(res => {
        if (res.data.errors) {
          setErrors(res.data.errors);
        } else if (res.data.status === 0) {
          toast.error('Server Error. Please try again later', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setErrors({ status: '' });
        } else {
          toast.success('Status edited.', { position: toast.POSITION.BOTTOM_RIGHT });
          setEdit(!edit);
          setErrors({ status: '' });
          let status = statusTypes.indexOf(details.status);
          if (status === -1) {
            status = 1;
          } else {
            status += 1;
          }
          setDetails({ status: status });
        }
      })
      .catch(err => {
        toast.error('Cannot Change', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  const onChange = e => {
    setDetails({ ...payment, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <Button onClick={toggle}>Change Details</Button>
      <Modal isOpen={modal} toggle={toggle} className="black_dashboard">
        <ModalHeader toggle={toggle}>Change Payment Details:</ModalHeader>
        <ModalBody>
          <p style={{ wordBreak: 'break-all' }}>
            <div className="content">
              {!edit ? (
                <>
                  <Row>
                    <Col md={6} sm={6}>
                      Status:{' '}
                      {details.status === 1
                        ? 'Initiated'
                        : details.status === 2
                        ? 'Success'
                        : 'Fail'}
                    </Col>
                  </Row>
                </>
              ) : (
                <Form>
                  <Row>
                    <Col md={2} sm={6}>
                      <Label>Status</Label>
                    </Col>
                    <Col md={4} sm={6}>
                      <Input
                        onChange={onChange}
                        value={details.status}
                        error={errors.status ? errors.status : ''}
                        type="select"
                        name="status"
                        id="status"
                        style={{ color: 'black' }}
                        invalid={errors.status ? true : false}
                      >
                        {statusTypes.map((statusValue, index) => {
                          return (
                            <option key={index} name={statusValue}>
                              {statusValue}
                            </option>
                          );
                        })}
                      </Input>
                      <FormFeedback>{errors.status}</FormFeedback>
                    </Col>
                  </Row>
                </Form>
              )}
            </div>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={!edit ? toggle : toggleSave}>
            {edit ? 'Save' : 'OK'}
          </Button>

          <Button color="secondary" onClick={toggleEdit}>
            {edit ? 'Cancel' : 'Edit'}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default VerifyPaymentModal;
