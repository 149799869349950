/*eslint-disable*/
import React from 'react';

// reactstrap components
import { Row, Container } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

function Footer() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li>
                <a href="https://herc.ws/board/profile/48-dastgir/" target="_blank">
                  Dastgir
                </a>
              </li>
              <li>
                <a
                  href="https://herc.ws/board/topic/11595-ddelaysanti-nodelays-releasing-this-weekend"
                  target="_blank"
                >
                  Forum Link
                </a>
              </li>
              <li>
                <a href="https://discord.gg/sb4mkdH" target="_blank">
                  Discord(Support)
                </a>
              </li>
            </ul>
          </nav>
          <div className="credits ml-auto">
            <span className="copyright">
              © {new Date().getFullYear()}, made with {<FontAwesomeIcon icon={faHeart} />}
              by Dastgir
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
