import React from 'react';

// reactstrap components
import axios from 'axios';

import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Services from '../../../components/dashboard/Services';

class SubscriptionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingList: true,
      loadingLog: true,
      subscriptions: {},
      colSize: this.props.colSize ? this.props.colSize : 12,
    };
  }
  componentDidMount() {
    axios
      .get('/api/subscriptions/expired')
      .then(res => {
        this.setState({
          loadingListExpired: false,
          subscriptionExpired: res.data.subscription,
        });
      })
      .catch(err => {
        console.log(err.response);
      });
    axios
      .get('/api/subscriptions/active')
      .then(res => {
        this.setState({
          loadingList: false,
          subscription: res.data.subscription,
        });
      })
      .catch(err => {
        console.log(err.response);
      });
    axios
      .get('/api/subscriptions/pending')
      .then(res => {
        this.setState({
          loadingLog: false,
          pendingLog: res.data.log,
        });
      })
      .catch(err => {
        console.log(err.response);
      });
  }
  render() {
    return (
      <>
        <Services
          subscription={this.state.loadingList === false ? this.state.subscription : null}
          pendingPayment={this.state.loadingLog === false ? this.state.pendingLog : null}
          colSize={this.state.colSize}
        />

        {this.state.subscriptionExpired && (
          <Services
            subscription={
              this.state.loadingListExpired === false ? this.state.subscriptionExpired : null
            }
            expired={true}
            message="Expired Subscriptions"
            colSize={this.state.colSize}
          />
        )}

        {(this.state.loadingList === true || this.state.loadingLog === true) && (
          <div className="content">
            <p>
              Loading List of {this.state.loadingList === true ? 'Orders' : 'Pending Orders'}...
            </p>
          </div>
        )}
      </>
    );
  }
}

SubscriptionList.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(
  mapStateToProps,
  null,
)(SubscriptionList);
