import React from 'react';
//import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import axios from 'axios';
//import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING } from './types';
import { Button } from 'reactstrap';
import RefreshPage from '../../../components/dashboard/payments/paytm_refresh';

import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

class PayTM extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: false,
      html: '',
      plan_id: this.props.plan_id ? this.props.plan_id : 0,
    };
    this.send = this.send.bind(this);
  }
  send() {
    const userData = {
      id: this.props.auth.user.id,
      //email: 'dastgirpojee@',
      //mob_no: '8080427192',
      plan: this.state.plan_id,
    };
    axios
      .post('/api/payments/paytm/init', userData)
      .then(res => {
        console.log(res.data);
        this.setState({
          data: true,
          html: res.data,
        });
      })
      .catch(err => {
        console.log('Reducer Error', err);
        console.log(err.response.data);
        /*
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        */
      });
  }
  render() {
    if (this.state.plan_id <= 0) {
      this.props.history.push('/dashboard');
      return <></>;
    } else if (this.state.data) {
      return (
        <div className="content">
          <RefreshPage data={this.state.html}></RefreshPage>
        </div>
      );
    } else if (this.state.plan_id > 0) {
      this.send();
      return <div className="content">Please do not refresh</div>;
    } else {
      return (
        <div className="content">
          <Button onClick={this.send}>Test Payload</Button>
        </div>
      );
    }
  }
}

PayTM.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(PayTM);
