import React from 'react';
import { Link } from 'react-router-dom';
// nodejs library that concatenates strings
import classnames from 'classnames';
import { logoutUser } from '../../../redux/action/authActions';
import { withRouter } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faGithub,
  faFacebookSquare,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// reactstrap components
import { Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav, Container } from 'reactstrap';

import LoginModal from '../../LoginModal';

import { useTranslation } from 'react-i18next';

function LandingNavbar(props) {
  const { t } = useTranslation('landing' /*{ useSuspense: false }*/);
  const [navbarColor, setNavbarColor] = React.useState('navbar-transparent');
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = e => {
    setNavbarCollapse(!navbarCollapse);
    //document.documentElement.classList.toggle('nav-open');
  };

  const onLogoutClick = e => {
    e.preventDefault();
    props.logoutUser();
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 299 || document.body.scrollTop > 299) {
        setNavbarColor('');
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
        setNavbarColor('navbar-transparent');
      }
    };

    window.addEventListener('scroll', updateNavbarColor);

    return function cleanup() {
      window.removeEventListener('scroll', updateNavbarColor);
    };
  }, [props.auth.isAuthenticated]);
  return (
    <Navbar
      className={
        navbarCollapse
          ? classnames('fixed-top', navbarColor, 'nav-open')
          : classnames('fixed-top', navbarColor)
      }
      color-on-scroll="300"
      expand="lg"
    >
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            to="/index"
            target="_blank"
            title="Navigation Bar"
            tag={Link}
          >
            {t('website_name', 'dDelays')}
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames('navbar-toggler navbar-toggler', {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse className="justify-content-end" navbar isOpen={navbarCollapse}>
          <Nav navbar={true}>
            <NavItem>
              <NavLink href="https://discord.gg/sb4mkdH" target="_blank">
                {' '}
                {/*to="/about-us" tag={Link}*/}
                <i className="nc-icon nc-layout-11" /> {t('discord', 'Discord')}
              </NavLink>
            </NavItem>
            <NavItem>
              {props.auth.isAuthenticated === true && (
                <NavLink tag={Link} to="#" onClick={onLogoutClick}>
                  <i className="nc-icon nc-book-bookmark" /> <span>{t('logout', 'Logout')}</span>
                </NavLink>
              )}
              {props.auth.isAuthenticated === false && (
                <NavLink tag={Link} to="#">
                  <i className="nc-icon nc-book-bookmark" />{' '}
                  <LoginModal>{t('login', 'Login')}</LoginModal>
                </NavLink>
              )}
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.google.com/"
                target="_blank"
                title="Follow us on Twitter"
              >
                <FontAwesomeIcon icon={faTwitter} />
                <p className="d-lg-none">{t('twitter', 'Twitter')}</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.google.com/"
                target="_blank"
                title="Like us on Facebook"
              >
                <FontAwesomeIcon icon={faFacebookSquare} />
                <p className="d-lg-none">{t('facebook', 'Facebook')}</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.google.com/"
                target="_blank"
                title="Follow us on Instagram"
              >
                <FontAwesomeIcon icon={faInstagram} />
                <p className="d-lg-none">{t('instagram', 'Instagram')}</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.github.com/dastgirp/"
                target="_blank"
                title="Star on GitHub"
              >
                <FontAwesomeIcon icon={faGithub} />
                <p className="d-lg-none">{t('github', 'Github')}</p>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

LandingNavbar.propTypes = {
  auth: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(
  mapStateToProps,
  { logoutUser },
)(withRouter(LandingNavbar));
