import React from 'react';

import { Col, Row } from 'reactstrap';
import {
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Table,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  CardFooter,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import SubscriptionModal from './subscription/SubscriptionModal';
import InstallationModal from './subscription/InstallationModal';
import VerifyPaymentModal from './subscription/VerifyPaymentModal';

class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: this.props.message ? this.props.message : 'Your subscriptions',
      colSize: this.props.colSize ? this.props.colSize : '12',
      subscription: this.props.subscription,
      isAdmin: this.props.isAdmin ? this.props.isAdmin : false,
      paymentPending: this.props.pendingPayment ? this.props.pendingPayment : undefined,
      dropdown: {},
      openDropdown: {},
      expired: this.props.expired ? this.props.expired : false,
    };
    this.table = React.createRef();
    this.toggle = this.toggle.bind(this);
    this.openDropdown = this.openDropdown.bind(this);
    this.callbackDropdown = this.callbackDropdown.bind(this);
    this.changePaymentLog = this.changePaymentLog.bind(this);
  }

  changePaymentLog(index, status) {
    var paymentPendingState = this.state.paymentPending;
    paymentPendingState[index].status = status;
    this.setState(prevState => ({ paymentPending: paymentPendingState }));
  }

  toggle(e) {
    var name = e.target.name;
    if (name) {
      this.setState(prevState => ({
        dropdown: {
          [name]: !prevState.dropdown[name],
        },
      }));
    }
  }

  openDropdown(value, id) {
    this.setState(prevState => ({
      dropdown: {
        [id]: !prevState.dropdown[id],
      },
      openDropdown: {
        [id]: value,
      },
    }));
  }

  callbackDropdown(id, value) {
    if (!value) {
      this.setState(prevState => ({
        openDropdown: {
          [id]: 0,
        },
      }));
    }
  }

  render() {
    return (
      <div className="content">
        <Row>
          {this.props.subscription && (
            <Col md={this.state.colSize}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">{this.state.message}</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive={true} striped innerRef={this.table}>
                    <thead className="text-primary">
                      <tr>
                        <th>Sr. No</th>
                        <th>Subscription ID</th>
                        <th>Plan Name</th>
                        <th>Valid until</th>
                        {!this.state.expired && <th className="text-center">Details</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.subscription.length === 0 && (
                        <tr key={'no_key'}>
                          <td colspan="6" className="text-center">
                            No Details
                          </td>
                        </tr>
                      )}
                      {this.props.subscription.map((subscription, index) => {
                        const date = new Date(subscription.validity * 1000);
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{subscription._id}</td>
                            <td>{subscription.plan.name}</td>
                            <td>{date.toUTCString()}</td>
                            {!this.state.expired && (
                              <td className="text-center">
                                <Dropdown
                                  name={subscription._id}
                                  isOpen={this.state.dropdown[subscription._id]}
                                  toggle={this.toggle}
                                  direction={'left'}
                                >
                                  <DropdownToggle name={subscription._id} caret>
                                    Details & Installation
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem header>Details</DropdownItem>
                                    <DropdownItem
                                      onClick={() => this.openDropdown(1, subscription._id)}
                                    >
                                      Auth Details
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => this.openDropdown(2, subscription._id)}
                                    >
                                      Server Details
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem
                                      onClick={() => this.openDropdown(3, subscription._id)}
                                    >
                                      Installation
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                                {this.state.openDropdown[subscription._id] === 1 && (
                                  <>
                                    <SubscriptionModal
                                      buttonLabel="Auth Details"
                                      licenseKey={subscription.license_key}
                                      token={subscription.token}
                                      subscriptionId={subscription._id}
                                      callback={this.callbackDropdown}
                                      modalShow={true}
                                    />
                                  </>
                                )}
                                {this.state.openDropdown[subscription._id] === 2 && (
                                  <SubscriptionModal
                                    buttonLabel="Server Details"
                                    serverDetails={subscription.server_status}
                                    subscriptionId={subscription._id}
                                    callback={this.callbackDropdown}
                                    modalShow={true}
                                  />
                                )}
                                {this.state.openDropdown[subscription._id] === 3 && (
                                  <InstallationModal
                                    buttonLabel="Installation Guide"
                                    serverDetails={subscription.server_status}
                                    subscriptionId={subscription._id}
                                    callback={this.callbackDropdown}
                                    modalShow={true}
                                  />
                                )}
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          )}
          {this.props.pendingPayment && (
            <Col md={this.state.colSize}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">All Payments</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive={true} striped>
                    <thead className="text-primary">
                      <tr>
                        <th>Sr. No</th>
                        <th>Order ID/Transaction ID</th>
                        <th>Plan Name</th>
                        <th>Amount(USD)</th>
                        <th className="text-center">Payment Mode</th>
                        <th className="text-center">Status</th>
                        {this.state.isAdmin && <th className="text-center">Modify</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.pendingPayment.map((payment_log, index) => {
                        //const date = new Date(subscription.validity * 1000);
                        if (payment_log.payment_mode !== 'paypal') return null;
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {payment_log.order_id}
                              {this.state.isAdmin ? `:(${payment_log.email})` : ''}
                            </td>
                            <td>{payment_log.plan_id.name}</td>
                            <td>{payment_log.plan_id.amount.usd}$</td>
                            <td className="text-center">{payment_log.payment_mode}</td>
                            <td className="text-center">
                              {payment_log.status === 1
                                ? 'Pending for Verification'
                                : payment_log.status === 2
                                ? 'Success'
                                : 'Failure'}
                            </td>
                            {this.state.isAdmin && (
                              <VerifyPaymentModal
                                payment={payment_log}
                                idx={index}
                                callback={() => this.changePaymentLog}
                              />
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
                <CardFooter className="text-primary">
                  If you have Successful payment, but subscription is not showing. Please enter your
                  OrderID at{' '}
                  <a href="https://ddelays.dastgir.tech/dashboard/payment/paytm/status">
                    https://ddelays.dastgir.tech/dashboard/payment/paytm/status
                  </a>
                </CardFooter>
              </Card>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

Services.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(withRouter(Services));
