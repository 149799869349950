/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardText,
  CardBody,
} from 'reactstrap';

import axios from 'axios';
import { toast } from 'react-toastify';

const InstallationModal = props => {
  const { buttonLabel, subscriptionId } = props;

  const [modal, setModal] = useState(props.modalShow ? props.modalShow : false);

  const toggle = () => {
    if (props.callback) {
      props.callback(subscriptionId, !modal);
    }
    setModal(!modal);
  };

  const download = (content, name) => {
    const url = window.URL.createObjectURL(new Blob([content]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
  };

  const downloadToken = data => {
    axios
      .post('/api/download/token', data)
      .then(res => {
        if (res.data.valid) {
          download(res.data.key, 'dDelaysKey');
          download(res.data.token, 'dDelaysToken');
        } else {
          toast.error('Cannot Download Token. Please try again later', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(err => {
        toast.error('Cannot Download Token', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  const downloadPlugin = data => {
    axios
      .post('/api/download/plugin', data)
      .then(res => {
        if (res.data.valid) {
          download(res.data.plugin, 'dDelays.c');
        } else {
          toast.error('Cannot Download Plugin. Please try again later', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(err => {
        toast.error('Cannot Download Plugin', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  const downloadPluginHeader = data => {
    axios
      .post('/api/download/plugin_header', data)
      .then(res => {
        if (res.data.valid) {
          download(res.data.plugin_header, 'ddelays_hpm.h');
        } else {
          toast.error('Cannot Download Plugin Header. Please try again later', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(err => {
        toast.error('Cannot Download Plugin Header', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  const downloadBattleConf = data => {
    axios
      .post('/api/download/battle', data)
      .then(res => {
        if (res.data.valid) {
          download(res.data.battle, 'dDelays.conf');
        } else {
          toast.error('Cannot Download Battle Config. Please try again later', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(err => {
        toast.error('Cannot Download Battle Config', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  const downloadLib = e => {
    e.preventDefault();
    const data = { id: subscriptionId };
    downloadToken(data);
    downloadPlugin(data);
    downloadPluginHeader(data);
    downloadBattleConf(data);
    axios
      .post('/api/download/lib', data)
      .then(res => {
        if (res.data.valid) {
          download(Uint8Array.from(res.data.lib.data).buffer, 'libddelays.so');
        } else {
          toast.error('Cannot Download Library. Please try again later', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(err => {
        toast.error('Cannot Download Library', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  const downloadLibCurl = e => {
    e.preventDefault();
    const data = { id: subscriptionId };
    axios
      .post('/api/download/libcurl', data)
      .then(res => {
        if (res.data.valid) {
          download(Uint8Array.from(res.data.libcurl.data).buffer, 'libcurl-gnutls.so.4');
        } else {
          toast.error('Cannot Download Curl. Please try again later', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(err => {
        toast.error('Cannot Download Curl', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  return (
    <div>
      {!props.modalShow && <Button onClick={toggle}>{buttonLabel}</Button>}
      <Modal isOpen={modal} toggle={toggle} size="lg" className="black_dashboard">
        <ModalHeader toggle={toggle}>{buttonLabel}:</ModalHeader>
        <ModalBody>
          <p style={{ wordBreak: 'break-all' }}>Hercules:</p>
          <p style={{ wordBreak: 'break-all' }}>
            <ol className="text-dark">
              <li className="text-dark">
                Download All Files (
                <b>
                  libddelays.so, ddelays.c, ddelays_hpm.h, ddelays.conf, dDelaysKey, dDelaysToken
                </b>
                ):{' '}
                <Button color="primary" onClick={downloadLib}>
                  Download Required Files
                </Button>
                <Button color="primary" onClick={downloadLibCurl}>
                  Download Curl for CentOS
                </Button>
              </li>
              <li className="text-dark">
                Move <b>ddelays.c</b>, <b>ddelays_hpm.h</b> and <b>libddelays.so</b> to{' '}
                <b>src/plugins/</b> folder
              </li>
              <li className="text-dark">
                CentOS: (Library Folder: /usr/lib64/) <br />
                Ubuntu: (Library Folder: /usr/lib/) <br />
                Move <b>libddelays.so</b> (If CentOS, move <b>libcurl-gnutls.so.4</b> too) to{' '}
                <b>Library Folder</b> folder
              </li>
              <li className="text-dark">
                Move <b>dDelays.conf</b> to <b>conf/map/battle/</b>
              </li>
              <li className="text-dark">
                Move <b>dDelaysKey</b> and <b>dDelaysToken</b> to <b>conf/</b> folder
              </li>
              <li className="text-dark">
                Install Curl by typing following in SSH:{' '}
                <b>sudo apt-get install curl libcurl4-gnutls-dev</b>
                (For CentOS, use <b>sudo yum install libcurl libcurl-devel</b>)
              </li>
              <li className="text-dark">
                Install plugin(<b>ddelays.c</b>) as mentioned in{' '}
                <a href="https://github.com/HerculesWS/Hercules/wiki/Hercules-Plugin-Manager">
                  https://github.com/HerculesWS/Hercules/wiki/Hercules-Plugin-Manager
                </a>
              </li>
              <li className="text-dark">
                Edit <b>src/plugins/MakeFile.in</b>: <br />
                Find
                <Card className="card-white">
                  <CardBody>
                    <CardText>
                      <span>
                        ../../plugins/%@DLLEXT@: %.c $(ALL_H) $$(shell ls %/* 2>/dev/null)
                      </span>
                      <br />
                      <span>@echo " CC $&lt;"</span>
                      <br />
                      <span>
                        @$(CC) $(COMMON_INCLUDE) $(THIRDPARTY_INCLUDE) @PLUGINSTATIC@ @DEFS@
                        @CFLAGS@ @CPPFLAGS@ @LDFLAGS@ @SOFLAGS@ -o $@ $&lt;
                      </span>
                    </CardText>
                  </CardBody>
                </Card>
                Replace with:
                <Card className="card-white">
                  <CardBody>
                    <CardText>
                      <span>
                        ../../plugins/%@DLLEXT@: %.c $(ALL_H) $$(shell ls %/* 2>/dev/null)
                      </span>
                      <br />
                      <span>@echo " CC $&lt;"</span>
                      <br />
                      <span>
                        @$(CC) $(COMMON_INCLUDE) $(THIRDPARTY_INCLUDE) @PLUGINSTATIC@ @DEFS@
                        @CFLAGS@ @CPPFLAGS@ @LDFLAGS@ @SOFLAGS@ -o $@ $&lt;
                        <font color={'green'}>-lcurl -L./ -lddelays</font>
                      </span>
                    </CardText>
                  </CardBody>
                </Card>
              </li>
            </ol>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default InstallationModal;
