import React from 'react';

// reactstrap components
import axios from 'axios';

import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Card, CardBody, CardHeader, CardText, CardTitle, Col, Row } from 'reactstrap';
import PaymentMethodModal from '../../../components/dashboard/payments/PaymentMethodModal';
import PayTM from '../Payments/PayTM';
import PayPal from '../Payments/PayPal';

class BuySubscriptionPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      plans: {},
      paymentSelected: '',
      plan_id: 0,
      amount: 99.99,
    };
    this.proceedPayment.bind(this);
  }
  componentDidMount() {
    axios
      .get('/api/plans/all')
      .then(res => {
        this.setState({
          loading: false,
          plans: res.data.plan,
        });
      })
      .catch(err => {
        console.log(err.response);
      });
  }

  proceedPayment(paymentMode, planId, amount) {
    this.setState({
      paymentSelected: paymentMode,
      plan_id: planId.toString(),
      amount: amount,
    });
  }
  render() {
    if (this.state.loading) {
      return <div className="content">Loading...</div>;
    }
    if (this.state.paymentSelected) {
      switch (this.state.paymentSelected) {
        default:
        case 'paytm':
          return <PayTM plan_id={this.state.plan_id} />; // Amount not required

        case 'paypal':
          return <PayPal plan_id={this.state.plan_id} amount={this.state.amount} />;
      }
    }

    return (
      <div className="content">
        <Row>
          {this.state.plans.map((plan, index) => {
            return (
              <Col md="4" key={index}>
                <Card>
                  <CardHeader tag="h3" className="text-center">
                    {plan.name}
                  </CardHeader>
                  <CardBody className="text-center">
                    <CardTitle>Validity: {plan.validity} Days</CardTitle>
                    <CardText>
                      Price:
                      <br /> USD: {plan.amount.usd} $<br /> INR: {plan.amount.inr} ₹<br />
                    </CardText>
                    <PaymentMethodModal
                      callback={this}
                      plan_id={plan.plan_id}
                      amount={{ usd: plan.amount.usd, inr: plan.amount.inr }}
                      className="black_dashboard"
                    >
                      Order Now
                    </PaymentMethodModal>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
    );

    //return <div className="content">Loading Done</div>;
  }
}

BuySubscriptionPage.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(
  mapStateToProps,
  null,
)(BuySubscriptionPage);
