import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
const PrivateRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      (auth.isAuthenticated === true && auth.user.role_id >= rest.role_id ) ? <Component {...props} /> : <Redirect to="/" />
     }
  />
  //console.log(rest.role_id, auth, auth.isAuthenticated === true && rest.role_id >= auth.user.role_id) && 
);
PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(PrivateRoute);
