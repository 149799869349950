/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Input, Form, FormFeedback, Label, Row, Col } from 'reactstrap';

import axios from 'axios';
import { toast } from 'react-toastify';

const SubscriptionModal = props => {
  const { licenseKey, token, buttonLabel, subscriptionId } = props;
  var { serverDetails } = props;

  const [modal, setModal] = useState(props.modalShow ? props.modalShow : false);
  const [edit, setEdit] = useState(false);
  const [details, setDetails] = useState({
    ...serverDetails,
  });
  const [errors, setErrors] = useState({ ip: '', port: '', emulator: '', serverType: '' });

  const toggle = () => {
    if (props.callback) {
      props.callback(subscriptionId, !modal);
    }
    setModal(!modal);
  };

  const toggleEdit = () => {
    setEdit(!edit);
  };

  const toggleSave = () => {
    const data = {
      id: subscriptionId,
      details: { ...details },
    };
    axios
      .post('/api/subscriptions/edit', data)
      .then(res => {
        if (res.data.isValid) {
          if (res.data.status === 0) {
            toast.error('Server Error. Please try again later', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else {
            toast.success('Server Details edited.', { position: toast.POSITION.BOTTOM_RIGHT });
            setEdit(!edit);
          }
          setErrors({});
        } else {
          setErrors(res.data.errors);
          toast.error('Cannot Save. Please check the error', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(err => {
        toast.error('Cannot Save', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  const onChange = e => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const emulator = ['Hercules', 'rAthena', 'eAmod', 'rAmod', 'brAthena', 'Custom'];
  const serverType = ['Renewal', 'Pre-Renewal'];

  return (
    <div>
      {!props.modalShow && <Button onClick={toggle}>{buttonLabel}</Button>}
      <Modal isOpen={modal} toggle={toggle} className="black_dashboard">
        <ModalHeader toggle={toggle}>{buttonLabel}:</ModalHeader>
        <ModalBody>
          <p style={{ wordBreak: 'break-all' }}>
            {serverDetails && (
              <div className="content">
                {!edit ? (
                  <>
                    <Row>
                      <Col md={6} sm={6}>
                        IP: {details.ip}
                      </Col>
                      <Col md={6} sm={6}>
                        Port: {details.port}
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col md={12} sm={12}>
                        Emulator: {details.emulator}
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col md={12} sm={12}>
                        Server Type: {details.serverType}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Form>
                    <Row>
                      <Col md={2} sm={6}>
                        <Label>IP</Label>
                      </Col>
                      <Col md={4} sm={6}>
                        <Input
                          onChange={onChange}
                          value={details.ip}
                          error={errors.ip}
                          type="text"
                          name="ip"
                          id="ip"
                          style={{ color: 'black' }}
                          invalid={errors.ip ? true : false}
                        />
                        <FormFeedback>{errors.ip}</FormFeedback>
                      </Col>
                      <Col md={2} sm={6}>
                        <Label>Port</Label>
                      </Col>
                      <Col md={4} sm={6}>
                        <Input
                          onChange={onChange}
                          value={details.port}
                          error={errors.port}
                          type="text"
                          name="port"
                          id="port"
                          style={{ color: 'black' }}
                          invalid={errors.port ? true : false}
                        />
                        <FormFeedback>{errors.port}</FormFeedback>
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col md={4} sm={6}>
                        <Label>Emulator</Label>
                      </Col>
                      <Col md={4} sm={6}>
                        <Input
                          onChange={onChange}
                          value={details.emulator}
                          error={errors.emulator}
                          type="select"
                          name="emulator"
                          id="emulator"
                          style={{ color: 'black' }}
                          invalid={errors.emulator ? true : false}
                        >
                          {emulator.map((emulatorValue, index) => {
                            return (
                              <option key={index} name={emulatorValue}>
                                {emulatorValue}
                              </option>
                            );
                          })}
                        </Input>
                        <FormFeedback>{errors.emulator}</FormFeedback>
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col md={4} sm={6}>
                        <Label>Server Type</Label>
                      </Col>
                      <Col md={4} sm={6}>
                        <Input
                          onChange={onChange}
                          value={details.serverType}
                          error={errors.serverType}
                          type="select"
                          name="serverType"
                          id="serverType"
                          style={{ color: 'black' }}
                          invalid={errors.serverType ? true : false}
                        >
                          {serverType.map((serverTypeValue, index) => {
                            return (
                              <option key={index} name={serverTypeValue}>
                                {serverTypeValue}
                              </option>
                            );
                          })}
                        </Input>
                        <FormFeedback>{errors.serverType}</FormFeedback>
                      </Col>
                    </Row>
                  </Form>
                )}
              </div>
            )}
            {!serverDetails && (
              <>
                Token ID: {token}
                <br />
                License Key: {licenseKey}
              </>
            )}
          </p>
        </ModalBody>
        <ModalFooter>
          {!serverDetails && (
            <Button color="secondary" onClick={toggle}>
              OK
            </Button>
          )}

          {serverDetails && (
            <>
              <Button color="secondary" onClick={!edit ? toggle : toggleSave}>
                {edit ? 'Save' : 'OK'}
              </Button>

              <Button color="secondary" onClick={toggleEdit}>
                {edit ? 'Cancel' : 'Edit'}
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SubscriptionModal;
